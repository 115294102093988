"use strict";

/*-----------------------------------------------------------------------------------
/*
/* Main JS
/*
-----------------------------------------------------------------------------------*/

(function ($) {
  /*---------------------------------------------------- */
  /* Preloader
  ------------------------------------------------------ */
  $(window).load(function () {
    // will first fade out the loading animation
    $("#status").fadeOut("slow");

    // will fade out the whole DIV that covers the website.
    $("#preloader").delay(500).fadeOut("slow").remove();
  });

  /*----------------------------------------------------*/
  /* Adjust Primary Navigation Background Opacity
  ------------------------------------------------------*/
  $(window).on('scroll', function () {
    var h = $('header').height();
    var y = $(window).scrollTop();
    var header = $('#main-header');
    if (y > h + 30 && $(window).outerWidth() > 768) {
      header.addClass('opaque');
    } else {
      if (y < h + 30) {
        header.removeClass('opaque');
      } else {
        header.addClass('opaque');
      }
    }
  });

  /*-----------------------------------------------------*/
  /* Alert Boxes
    -------------------------------------------------------*/
  $('.alert-box').on('click', '.close', function () {
    $(this).parent().fadeOut(500);
  });

  /*-----------------------------------------------------*/
  /* Mobile Menu
  ------------------------------------------------------ */
  var menu_icon = $("<span class='menu-icon'></span>");
  var toggle_button = $("<a>", {
    id: "toggle-btn",
    html: "<span class='menu-text'>Menu</span>",
    title: "Menu",
    href: "#"
  });
  var nav_wrap = $('nav#nav-wrap');
  var nav = $("ul#nav");

  /* if JS is enabled, remove the two a.mobile-btns
     and dynamically prepend a.toggle-btn to #nav-wrap */
  nav_wrap.find('a.mobile-btn').remove();
  toggle_button.append(menu_icon);
  nav_wrap.prepend(toggle_button);
  toggle_button.on("click", function (e) {
    e.preventDefault();
    nav.slideToggle("fast");
  });
  if (toggle_button.is(':visible')) nav.addClass('mobile');
  $(window).resize(function () {
    if (toggle_button.is(':visible')) nav.addClass('mobile');else nav.removeClass('mobile');
  });
  $('ul#nav li a').on("click", function () {
    if (nav.hasClass('mobile')) nav.fadeOut('fast');
  });

  /*----------------------------------------------------*/
  /* Smooth Scrolling
  ------------------------------------------------------ */
  $('.smoothscroll').on('click', function (e) {
    e.preventDefault();
    var target = this.hash,
      $target = $(target);
    $('html, body').stop().animate({
      'scrollTop': $target.offset().top
    }, 800, 'swing', function () {
      window.location.hash = target;
    });
  });

  /*----------------------------------------------------*/
  /* Highlight the current section in the navigation bar
  ------------------------------------------------------*/
  var sections = $("section"),
    navigation_links = $("#nav-wrap a");
  if ($("body").hasClass('homepage')) {
    sections.waypoint({
      handler: function handler(event, direction) {
        var active_section;
        active_section = $(this);
        if (direction === "up") active_section = active_section.prev();
        var active_link = $('#nav-wrap a[href="#' + active_section.attr("id") + '"]');
        navigation_links.parent().removeClass("current");
        active_link.parent().addClass("current");
      },
      offset: '25%'
    });
  }

  /*----------------------------------------------------*/
  /*	contact form
  ------------------------------------------------------*/
  $(document).ready(function () {
    $('#contactForm').on('submit', function (event) {
      event.preventDefault();
      $('#image-loader').fadeIn();
      var contactFname = $('#contactForm #contactFname').val();
      var contactLname = $('#contactForm #contactLname').val();
      var contactEmail = $('#contactForm #contactEmail').val();
      var contactSubject = $('#contactForm #contactSubject').val();
      var contactMessage = $('#contactForm #contactMessage').val();
      var contactdata = "contactFname=" + contactFname + "& contactLname=" + contactLname + "& contactEmail=" + contactEmail + "& contactSubject=" + contactSubject + "& contactMessage=" + contactMessage;
      $.ajax({
        type: "POST",
        url: "get_response.php",
        data: contactdata,
        success: function success(data) {
          $('#image-loader').fadeOut();
          $("#message-warning").hide;
          $("#message-success").html(data);
          $("#message-success").slideDown().fadeOut(3000);
          $('#contactForm').fadeOut();
        },
        error: function error(jqXHR, textStatus, errorThrown) {
          $('#image-loader').fadeOut();
          $("#message-warning").text(data);
          $("#message-warning").slideDown().fadeOut(3000);
          $('#contactForm').fadeOut();
        }
      });
      return false;
    });
  });

  /*-----------------------------------------------------*/
  /* Back to top
  ------------------------------------------------------ */
  var pxShow = 300; // height on which the button will show
  var fadeInTime = 400; // how slow/fast you want the button to show
  var fadeOutTime = 400; // how slow/fast you want the button to hide
  var scrollSpeed = 300; // how slow/fast you want the button to scroll to top. can be a value, 'slow', 'normal' or 'fast'

  // Show or hide the sticky footer button
  var scroll = jQuery(window).scroll(function () {
    if (jQuery(window).scrollTop() >= pxShow) {
      jQuery("#go-top").fadeIn(fadeInTime);
    } else {
      jQuery("#go-top").fadeOut(fadeOutTime);
    }
  });
})(jQuery);